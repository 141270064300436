@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

/* Home Css Start Here */
.chatgpt_home {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url('../img/background.jpg');
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
}

.chatgpt_home .prompt-input {
    border: 1px solid #fff;
    border-radius: 6px;
    box-shadow: none !important;
    outline: none !important;
    height: 50px;
    background: #00000085;
    color: #fff;
}

.chatgpt_home form {
    display: flex;
    margin-bottom: 30px;
}

.chatgpt_home .button-group button {
    background: #fff;
    color: #000;
    padding: 10px 20px;
    height: 100%;
}

.chatgpt_home form .form-group {
    flex: 1;
    margin-right: 15px;
}

.chatgpt_home .temperature_box {
    display: flex;
    align-items: center;
}

.chatgpt_home .temperature_box .form-control {
    width: 40px;
    padding: 0;
    border-radius: 0;
    margin-left: 10px;
    margin-top: -15px;
    text-align: center;
    background: transparent;
    border: 0;
    color: #fff;
    font-family: 'Roboto Condensed';
    font-size: 16px;
}